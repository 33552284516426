const hostWithoutPort = (host) => {
    return host.split(':').slice(0, 1)[0];
};

function getFallbackDomains() {
    const fallbackDomains = {
        en: `https://${process.env.laravel_domain_en}`,
        pl: `https://${process.env.laravel_domain_pl}`,
        ru: `https://${process.env.laravel_domain_ru}`,
        de: `https://${process.env.laravel_domain_en}`,
        es: `https://${process.env.laravel_domain_en}`,
        fr: `https://${process.env.laravel_domain_en}`,
        pt: `https://${process.env.laravel_domain_en}`,
        it: `https://${process.env.laravel_domain_en}`,
        vn: `https://${process.env.laravel_domain_en}`,
    };

    return fallbackDomains;
}

const locales = {
    en: {
        locale: 'en',
        localeParam: 'en',
        hasSeparateDomain: true,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_EN),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_EN,
    },
    pl: {
        locale: 'pl',
        localeParam: 'pl',
        hasSeparateDomain: process.env.NEXT_PUBLIC_HOST_PL !== process.env.NEXT_PUBLIC_HOST_EN,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_PL),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_PL,
    },
    de: {
        locale: 'de',
        localeParam: 'de',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_DE),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_DE,
    },
    es: {
        locale: 'es',
        localeParam: 'es',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_ES),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_ES,
    },
    fr: {
        locale: 'fr',
        localeParam: 'fr',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_FR),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_FR,
    },
    pt: {
        locale: 'pt',
        localeParam: 'pt',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_PT),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_PT,
    },
    ru: {
        locale: 'ru',
        localeParam: 'ru',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_RU),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_RU,
    },
    it: {
        locale: 'it',
        localeParam: 'it',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_IT),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_IT,
    },
    vi: {
        locale: 'vi',
        localeParam: 'vn',
        hasSeparateDomain: false,
        host: hostWithoutPort(process.env.NEXT_PUBLIC_HOST_VI),
        hostWithPort: process.env.NEXT_PUBLIC_HOST_VI,
    },
};

exports.locales = locales;
exports.getFallbackDomains = getFallbackDomains;
