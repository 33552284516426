import React from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { getAdwordsCookie } from '@helpers/adwords';

import Footer, { FooterProps } from './Footer';
import { HeaderProps } from './Header';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        dataLayer: any;
    }
}

const Header = dynamic(() => import('./Header').then((mod) => mod.Header), {
    ssr: true,
});

const DataLayerEvents = dynamic(() => import('./DataLayerEvents'), {
    ssr: false,
    // loading: () => {
    //   return <div>loading...</div>;
    // },
});
const AffiliateTrackingPixel = dynamic(() => import('./AffiliateTrackingPixel'), {
    ssr: false,
    // loading: () => {
    //   return <div>loading...</div>;
    // },
});

const AdwordsTrackingPixel = dynamic(() => import('./AdwordsTrackingPixel'), {
    ssr: false,
});

interface PageProps {
    isSeasonalPromoActive: boolean;
    seasonalPromoCounterValue: number | null;
    alternateQuery?: boolean | object;
    isPromoActive?: boolean;
    affiliateVersion?: boolean;
    headerProps?: HeaderProps;
    footerProps?: FooterProps;
}

interface LayoutProps {
    pageProps?: PageProps;
    headerProps?: HeaderProps;
    footerProps?: FooterProps;
}

export default function Layout({
    children,
    headerProps = {},
    footerProps = {},
    pageProps = {
        isPromoActive: false,
        isSeasonalPromoActive: false,
        seasonalPromoCounterValue: null,
        headerProps,
    },
}: React.PropsWithChildren<LayoutProps>) {
    const router = useRouter();

    return (
        <>
            <Header
                {...(pageProps.headerProps ? pageProps.headerProps : headerProps)}
                additionalHeaderAttribute={
                    pageProps.headerProps
                        ? pageProps.headerProps.additionalHeaderAttribute
                        : headerProps.additionalHeaderAttribute
                }
                showSeasonalPromoBar={pageProps.isSeasonalPromoActive}
                seasonalPromoCounterValue={pageProps.seasonalPromoCounterValue}
            />
            {children}
            <Footer
                footerInternalLinks={
                    pageProps?.affiliateVersion === true ? false : footerProps?.footerInternalLinks ?? true
                }
                alternateQuery={pageProps.alternateQuery}
                langSwitcherPathname={
                    pageProps.footerProps
                        ? pageProps.footerProps.langSwitcherPathname
                        : footerProps.langSwitcherPathname
                }
                {...(pageProps.footerProps ? pageProps.footerProps : footerProps)}
            />
            <DataLayerEvents />
            <AffiliateTrackingPixel />
            {getAdwordsCookie(router.query) && <AdwordsTrackingPixel />}
        </>
    );
}
