// type Route = {
//     name: string; // not sure if needed
//     fallback?: boolean; // set to true if page has no 'index.tsx|jsx' file; this will pass rendering to laravel
//     paths: {
//         default: string; // default name of path; if there is no localized path for locale then this one will be used
//         en?: string | false,
//         pl?: string | false, // if `false` page with this locale should be redirected to EN
//         ru?: string | false,
//         de?: string | false,
//         es?: string | false,
//         fr?: string | false,
//         pt?: string | false,
//         it?: string | false,
//         vn?: string | false,
//     }
//     children?: Route[];
//     featureToggleName?: string; // name of f-toggle cookie; cookie will look as follows: `feature_toggle_${featureToggleName}`; value of a cookie doesn't matter
//     featureToggleFallback?: boolean; // for new pages this should be `false` (will rewrite directly to 404) if this page exists in laravel this should be `true`
// }

const routes = {
    homepage: {
        name: '',
        paths: {
            default: '',
        },
        children: [
            {
                name: '_error',
                paths: {
                    default: '_error',
                },
            },
            {
                name: '404',
                paths: {
                    default: '404',
                },
            },
            {
                name: '410',
                paths: {
                    default: '410',
                },
            },
            {
                name: 'about',
                fallback: true,
                paths: {
                    default: 'about',
                    de: 'uber-getresponse',
                    it: 'informazioni',
                    pl: 'email-marketing/o-getresponse',
                    es: 'acerca-de-getresponse',
                    fr: 'a-propos-de-getresponse',
                    pt: 'sobre-getresponse',
                    vn: false,
                    ru: false,
                },
                children: [
                    {
                        name: 'whats-new',
                        paths: {
                            default: 'whats-new',
                            de: 'aktuelles',
                            it: 'nuove-funzionalita-getresponse',
                            pl: 'aktualnosci',
                            es: 'novedades',
                            fr: 'nouveautes',
                            pt: 'novidades',
                            vn: false,
                            ru: false,
                        },
                    },
                ],
            },
            {
                name: 'affiliate-programs',
                paths: {
                    default: 'affiliate-programs',
                    de: 'affiliate-programme',
                    es: 'programas-de-afiliados',
                    fr: 'programmes-d-affiliation',
                    it: 'programmi-di-affiliazione',
                    pl: 'programy-afiliacyjne',
                    pt: 'pt-programas-de-afiliados',
                    ru: false,
                },
            },
            {
                name: 'blog',
                paths: {
                    default: 'blog',
                    fr: false,
                    pt: 'blog',
                    zh: false,
                    tr: false,
                    vn: 'blog',
                },
                children: [
                    {
                        name: '[slug]',
                        paths: {
                            default: ':slug',
                        },
                    },
                    {
                        name: 'authors',
                        paths: {
                            default: 'authors',
                            fr: false,
                            zh: false,
                            tr: false,
                        },
                        children: [
                            {
                                name: '[slug]',
                                paths: {
                                    default: ':slug',
                                },
                            },
                        ],
                    },
                    {
                        name: 'preview',
                        fallback: true,
                        paths: {
                            default: 'preview',
                        },
                        children: [
                            {
                                name: '[id]',
                                paths: {
                                    default: ':id',
                                    disabled: true,
                                },
                            },
                        ],
                    },
                    {
                        name: 'search',
                        paths: {
                            default: 'search',
                            fr: false,
                            zh: false,
                            tr: false,
                        },
                    },
                    {
                        name: 'topic',
                        fallback: true,
                        paths: {
                            default: 'topic',
                            disabled: true,
                        },
                        children: [
                            {
                                name: '[slug]',
                                paths: {
                                    default: ':slug',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: 'customers',
                paths: {
                    default: 'customers',
                    pl: 'klienci',
                    zh: false,
                    tr: false,
                    ru: false,
                    vn: false,
                },
            },
            {
                name: 'esps',
                paths: {
                    default: 'esps',
                    ru: false,
                },
                children: [
                    {
                        name: '[slug]',
                        paths: {
                            default: ':slug',
                        },
                    },
                    {
                        name: 'preview',
                        paths: {
                            default: 'preview',
                            disabled: true,
                        },
                        children: [
                            {
                                name: '[id]',
                                paths: {
                                    default: ':id',
                                    disabled: true,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: 'features',
                paths: {
                    default: 'features',
                    pl: 'funkcjonalnosci',
                    de: 'funktionen',
                    es: 'funciones',
                    fr: 'caracteristiques',
                    pt: 'recursos',
                    ru: 'ru-features',
                    it: 'funzionalita',
                    vn: 'features',
                },
                children: [
                    {
                        name: 'ai-campaign-generator',
                        paths: {
                            default: 'ai-campaign-generator',
                            de: 'ki-kampagnen-generator',
                            it: 'ai-campaign-generator',
                            pl: 'generator-kampanii-marketingowych-ai',
                            es: 'generador-de-campanas-ia',
                            fr: 'generateur-ia-de-campagne',
                            pt: 'gerador-de-campanhas-ia',
                            vn: 'tao-chien-dich-ai',
                            ru: false,
                            ms: false,
                            zh: false,
                        },
                        children: [
                            {
                                name: 'versions',
                                fallback: true,
                                paths: {
                                    default: 'versions',
                                    disabled: true,
                                },
                                children: [
                                    {
                                        name: '1',
                                        paths: {
                                            default: '1',
                                            disabled: true,
                                            de: false,
                                            it: false,
                                            pl: false,
                                            es: false,
                                            fr: false,
                                            pt: false,
                                            vn: false,
                                            ru: false,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'course-creator',
                        paths: {
                            default: 'course-creator',
                            pl: 'tworzenie-kursow',
                            de: 'online-kurs-plattform',
                            es: 'creador-de-cursos-online',
                            fr: 'course-creator',
                            pt: 'criador-de-cursos-online',
                            it: 'crea-corsi-online',
                            vn: 'course-creator',
                            ru: false,
                        },
                    },
                    {
                        name: 'digital-marketing-reports',
                        paths: {
                            default: 'digital-marketing-reports',
                            de: 'marketing-reports',
                            it: 'digital-marketing-report',
                            pl: 'kreator-raportow-marketingowych',
                            es: 'marketing-analytics',
                            fr: 'rapports-marketing-numeriques',
                            pt: 'marketing-analytics',
                            vn: 'bao-cao-marketing',
                            ru: false,
                        },
                    },
                    {
                        name: 'email-marketing',
                        paths: {
                            default: 'email-marketing',
                            de: 'e-mail-marketing',
                            pt: 'marketing-por-e-mail',
                            fr: 'marketing-par-e-mail',
                            id: false,
                            nl: false,
                        },
                        children: [
                            {
                                name: 'ai-email-generator',
                                paths: {
                                    default: 'ai-email-generator',
                                    pt: 'gerador-de-email-ia',
                                    pl: 'generator-ai',
                                    de: 'ki-email-generator',
                                    es: 'generador-de-email-ia',
                                    fr: 'generateur-ia-email',
                                    it: 'ai-email-generator',
                                    vn: 'tao-email-ai',
                                    ru: false,
                                },
                                children: [
                                    {
                                        name: 'versions',
                                        fallback: true,
                                        paths: {
                                            default: 'versions',
                                            disabled: true,
                                        },
                                        children: [
                                            {
                                                name: '1',
                                                paths: {
                                                    default: '1',
                                                    disabled: true,
                                                    pt: false,
                                                    pl: false,
                                                    de: false,
                                                    es: false,
                                                    fr: false,
                                                    it: false,
                                                    vn: false,
                                                    ru: false,
                                                },
                                            },
                                            {
                                                name: '2',
                                                paths: {
                                                    default: '2',
                                                    disabled: true,
                                                    pt: false,
                                                    pl: false,
                                                    de: false,
                                                    es: false,
                                                    fr: false,
                                                    it: false,
                                                    vn: false,
                                                    ru: false,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'email-analytics',
                                paths: {
                                    default: 'email-analytics',
                                    pt: 'analise-email',
                                    pl: 'email-analiza',
                                    de: 'email-analysen',
                                    es: 'analisis-de-correo-electronico',
                                    fr: 'email-analytics',
                                    it: 'email-analytics',
                                    vn: 'email-analytics',
                                    ru: false,
                                },
                            },
                            {
                                name: 'email-assistant',
                                paths: {
                                    default: 'email-assistant',
                                    pl: 'asystent-pisania-maili-ai',
                                    de: 'ki-email-writer',
                                    it: 'ai-email-writer',
                                    vn: 'tro-ly-email',
                                    ru: false,
                                    pt: false,
                                    fr: false,
                                    es: false,
                                },
                                children: [
                                    {
                                        name: 'version',
                                        fallback: true,
                                        paths: {
                                            default: 'version',
                                            disabled: true,
                                        },
                                        children: [
                                            {
                                                name: 'a',
                                                paths: {
                                                    default: 'a',
                                                    disabled: true,
                                                    pl: false,
                                                    de: false,
                                                    it: false,
                                                    vn: false,
                                                    ru: false,
                                                    pt: false,
                                                    fr: false,
                                                    es: false,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'rss-to-email',
                                paths: {
                                    default: 'rss-to-email',
                                    pt: false,
                                    pl: false,
                                    de: false,
                                    es: false,
                                    fr: false,
                                    it: false,
                                    vn: false,
                                    ru: false,
                                },
                            },
                            {
                                name: 'templates',
                                paths: {
                                    default: 'templates',
                                    pl: 'szablony-newsletterow',
                                    de: 'newsletter-vorlagen',
                                    es: 'plantillas-de-email',
                                    pt: 'templates-de-email',
                                    vn: 'mau-template',
                                    it: 'newsletter-template',
                                    fr: false,
                                    zh: false,
                                    tr: false,
                                    ru: false,
                                    jp: false,
                                    sv: false,
                                    no: false,
                                    th: false,
                                    ko: false,
                                    ro: false,
                                    hu: false,
                                    dk: false,
                                },
                                children: [
                                    {
                                        name: '[slug]',
                                        paths: {
                                            default: ':slug',
                                            fr: false,
                                            zh: false,
                                            tr: false,
                                            ru: false,
                                            jp: false,
                                            sv: false,
                                            no: false,
                                            th: false,
                                            ko: false,
                                            ro: false,
                                            hu: false,
                                            dk: false,
                                        },
                                    },
                                ],
                            },
                            {
                                name: 'versions',
                                fallback: true,
                                paths: {
                                    default: 'versions',
                                    disabled: true,
                                },
                                children: [
                                    {
                                        name: 'a',
                                        paths: {
                                            default: 'a',
                                            disabled: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'landing-page-creator',
                        paths: {
                            default: 'landing-page-creator',
                            it: 'generatore-landing-page',
                            pl: 'kreator-landing-pages',
                            pt: 'criador-de-pagina-de-destino',
                            id: false,
                            nl: false,
                        },
                    },
                    {
                        name: 'paid-ads',
                        fallback: true,
                        paths: {
                            default: 'paid-ads',
                        },
                        children: [
                            {
                                name: 'google-ads-creator',
                                paths: {
                                    default: 'google-ads-creator',
                                    pt: 'google-ads-creator',
                                    pl: 'google-ads-creator',
                                    de: 'google-ads-creator',
                                    es: 'google-ads-creator',
                                    fr: 'google-ads-creator',
                                    it: 'gestione-campagne-google-ads',
                                    vn: 'google-ads-creator',
                                    ru: false,
                                },
                            },
                        ],
                    },
                    {
                        name: 'support',
                        paths: {
                            default: 'support',
                            pl: 'wsparcie-techniczne',
                            fr: 'assistance',
                            pt: 'assistencia',
                            it: 'assistenza',
                        },
                    },
                    {
                        name: 'website-builder',
                        paths: {
                            default: 'website-builder',
                            de: 'website-erstellen',
                            it: 'creare-sito-web',
                            pl: 'kreator-stron-www',
                            ru: 'konstruktor-sajtov',
                            id: false,
                            nl: false,
                        },
                    },
                    {
                        name: 'marketing-automation',
                        fallback: true,
                        paths: {
                            default: 'marketing-automation',
                            pl: 'marketing-automation',
                            de: 'marketingautomatisierung',
                            it: 'marketing-automation',
                            es: 'automatizacion-de-marketing',
                            fr: 'automatisation-du-marketing',
                            pt: 'automatizacao-de-marketing',
                            ru: 'marketing-automation',
                            vn: 'marketing-automation',
                        },
                        children: [
                            {
                                name: 'templates',
                                featureToggleName: 'marketing-automation-templates',
                                featureToggleFallback: true,
                                paths: {
                                    default: 'templates',
                                    de: 'workflows',
                                    it: 'template',
                                    pl: 'szablony',
                                    es: false,
                                    fr: false,
                                    pt: false,
                                    ru: false,
                                    vn: false,
                                },
                                children: [
                                    {
                                        name: '[slug]',
                                        featureToggleName: 'marketing-automation-templates',
                                        featureToggleFallback: true,
                                        paths: {
                                            default: ':slug',
                                            es: false,
                                            fr: false,
                                            pt: false,
                                            ru: false,
                                            vn: false,
                                        },
                                    },
                                    {
                                        name: 'preview',
                                        featureToggleName: 'marketing-automation-templates',
                                        featureToggleFallback: true,
                                        paths: {
                                            default: 'preview',
                                            disabled: true,
                                        },
                                        children: [
                                            {
                                                name: '[id]',
                                                paths: {
                                                    default: ':id',
                                                    disabled: true,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                name: 'homepage',
                paths: {
                    default: 'homepage',
                },
                children: [
                    {
                        name: 'a',
                        paths: {
                            default: 'a',
                            pl: false,
                            de: false,
                            es: false,
                            fr: false,
                            pt: false,
                            ru: false,
                            it: false,
                            vn: false,
                        },
                    },
                    {
                        name: 'b',
                        paths: {
                            default: 'b',
                            pl: false,
                            de: false,
                            es: false,
                            fr: false,
                            pt: false,
                            ru: false,
                            it: false,
                            vn: false,
                        },
                    },
                ],
            },
            {
                name: 'max',
                paths: {
                    default: 'max',
                    ru: false,
                },
                children: [
                    {
                        name: 'book-a-demo',
                        paths: {
                            default: 'book-a-demo',
                            ru: false,
                        },
                    },
                    {
                        name: 'industry-solutions',
                        paths: {
                            default: 'industry-solutions',
                        },
                        children: [
                            {
                                name: 'e-commerce',
                                paths: {
                                    default: 'e-commerce',
                                },
                            },
                            {
                                name: 'education',
                                paths: {
                                    default: 'education',
                                },
                            },
                            {
                                name: 'investment-and-trading',
                                paths: {
                                    default: 'investment-and-trading',
                                },
                            },
                            {
                                name: 'performance-marketing',
                                paths: {
                                    default: 'performance-marketing',
                                    pl: false,
                                    de: false,
                                    es: false,
                                    fr: false,
                                    pt: false,
                                    ru: false,
                                    it: false,
                                    vn: false,
                                },
                            },
                        ],
                    },
                    {
                        name: 'mailchimp-to-max',
                        paths: {
                            default: 'mailchimp-to-max',
                        },
                    },
                    {
                        name: 'partners',
                        paths: {
                            default: 'partners',
                            ru: false,
                        },
                        children: [
                            {
                                name: 'versions',
                                fallback: true,
                                paths: {
                                    default: 'versions',
                                    disabled: true,
                                },
                                children: [
                                    {
                                        name: 'b',
                                        paths: {
                                            default: 'b',
                                            disabled: true,
                                            pl: false,
                                            de: false,
                                            es: false,
                                            fr: false,
                                            pt: false,
                                            ru: false,
                                            it: false,
                                            vn: false,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'support-and-security',
                        fallback: true,
                        paths: {
                            default: 'support-and-security',
                        },
                        children: [
                            {
                                name: 'professional-services',
                                paths: {
                                    default: 'professional-services',
                                    ms: false,
                                    zh: false,
                                    ru: false,
                                },
                            },
                        ],
                    },
                    {
                        name: 'upgrade-max',
                        paths: {
                            default: 'upgrade-max',
                            ru: false,
                        },
                    },
                    {
                        name: 'upgrade-maxlegacy',
                        paths: {
                            default: 'upgrade-maxlegacy',
                            ru: false,
                        },
                    },
                    {
                        name: 'upgrade-maxsquare',
                        paths: {
                            default: 'upgrade-maxsquare',
                            ru: false,
                        },
                    },
                ],
            },
            {
                name: 'move-to-getresponse',
                paths: {
                    default: 'move-to-getresponse',
                    pl: 'migruj-do-getresponse',
                    id: false,
                    nl: false,
                    de: false,
                    ru: false,
                    it: false,
                    es: false,
                    fr: false,
                    pt: false,
                    zh: false,
                    tr: false,
                    vn: false,
                },
            },
            {
                name: 'ppc',
                paths: {
                    default: 'ppc',
                },
                children: [
                    {
                        name: 'email-marketing-1',
                        paths: {
                            default: 'email-marketing-1',
                            de: false,
                            it: false,
                            pl: false,
                            es: false,
                            fr: false,
                            pt: false,
                            vn: false,
                            ru: false,
                        },
                    },
                    {
                        name: 'email-marketing-2',
                        paths: {
                            default: 'email-marketing-2',
                            de: false,
                            it: false,
                            pl: false,
                            es: false,
                            fr: false,
                            pt: false,
                            vn: false,
                            ru: false,
                        },
                    },
                ],
            },
            {
                name: 'pricing',
                paths: {
                    default: 'pricing',
                },
                children: [
                    {
                        name: 'affiliates',
                        paths: {
                            default: 'affiliates',
                        },
                    },
                    {
                        name: 'trial',
                        paths: {
                            default: 'trial',
                            id: false,
                            nl: false,
                        },
                        children: [
                            {
                                name: 'versions',
                                fallback: true,
                                paths: {
                                    default: 'versions',
                                    disabled: true,
                                },
                                children: [
                                    {
                                        name: 'a',
                                        paths: {
                                            default: 'a',
                                            disabled: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                name: 'referral-program',
                fallback: true,
                paths: {
                    default: 'referral-program',
                    de: 'empfehlungsprogramm',
                    it: 'programma-di-referral',
                    pl: 'email-marketing/program-polecajacy',
                    es: 'programa-de-referidos',
                    fr: 'programme-de-parrainage',
                    pt: 'programa-de-referencias',
                    vn: 'referral-program',
                    ru: 'email-marketing/ru-referral-program',
                },
                children: [
                    {
                        name: 'refer-a-friend.html',
                        paths: {
                            default: 'refer-a-friend.html',
                            de: 'weiterempfehlen.html',
                            it: 'consiglialo-agli-amici.html',
                            pl: 'zapros-znajomego.html',
                            es: 'recomendar-un-amigo.html',
                            fr: 'parrainez-un-ami.html',
                            pt: 'indique-um-amigo.html',
                            vn: 'refer-a-friend.html',
                            ru: 'refer-a-friend.html',
                        },
                    },
                ],
            },
            {
                name: 'resources',
                paths: {
                    default: 'resources',
                    en: 'resources',
                    pl: 'baza-wiedzy',
                    de: 'resources',
                    ru: 'resources',
                    it: 'resources',
                    es: 'recursos',
                    fr: false,
                    pt: false,
                    zh: false,
                    tr: false,
                    vn: false,
                },
                children: [
                    {
                        name: 'courses',
                        fallback: true,
                        paths: {
                            default: 'courses',
                        },
                        children: [
                            {
                                name: 'google-search-for-beginners',
                                paths: {
                                    default: 'google-search-for-beginners',
                                    pl: false,
                                    de: false,
                                    ru: false,
                                    it: false,
                                    es: false,
                                    fr: false,
                                    pt: false,
                                    zh: false,
                                    tr: false,
                                    vn: false,
                                },
                            },
                        ],
                    },
                    {
                        name: 'reports',
                        fallback: true,
                        paths: {
                            default: 'reports',
                            de: 'reports',
                            pl: 'raporty',
                            es: 'reports',
                            fr: false,
                            pt: false,
                            ru: 'reports',
                            it: 'reports',
                            vn: false,
                        },
                        children: [
                            {
                                name: 'email-marketing-benchmarks',
                                paths: {
                                    default: 'email-marketing-benchmarks',
                                    de: 'email-marketing-benchmarks',
                                    pl: 'email-marketing-statystyki',
                                    es: 'benchmark-de-email-marketing',
                                    fr: false,
                                    pt: 'benchmark-de-email-marketing',
                                    ru: false,
                                    it: 'email-marketing-benchmark',
                                    vn: false,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: 'security',
                paths: {
                    default: 'security',
                    pl: 'email-marketing/bezpieczenstwo',
                    de: 'sicherheit',
                    es: 'seguridad',
                    fr: 'securite',
                    pt: 'seguranca',
                    ru: 'email-marketing/ru-security',
                    it: 'sicurezza',
                    jp: 'email-marketing/jp-security',
                    sv: 'email-marketing/sakerhet',
                    no: 'email-marketing/sikkerhet',
                    th: 'email-marketing/th-security',
                    ko: 'email-marketing/kr-security',
                    ro: 'email-marketing/securitate',
                    hu: 'biztonsag',
                    vn: 'security',
                    dk: 'security',
                },
            },
            {
                name: 'solutions',
                fallback: true,
                paths: {
                    default: 'solutions',
                    de: 'loesungen',
                    it: 'soluzioni',
                    pl: 'rozwiazania',
                    es: 'soluciones',
                    fr: 'solutions',
                    pt: 'solucoes',
                    vn: 'solutions',
                    ru: 'resheniya',
                },
                children: [
                    {
                        name: 'affiliate-marketing',
                        paths: {
                            default: 'affiliate-marketing',
                            de: false,
                            it: false,
                            pl: false,
                            es: false,
                            fr: false,
                            pt: false,
                            id: false,
                            vn: false,
                            ru: false,
                        },
                    },
                    {
                        name: 'customer-engagement',
                        paths: {
                            default: 'customer-engagement',
                            de: 'kundenbindung',
                            it: 'customer-engagement-software',
                            pl: 'komunikuj-i-angazuj',
                            es: 'compromiso-del-cliente',
                            fr: 'engagement-client',
                            pt: 'envolvimento-do-cliente',
                            id: 'customer-engagement',
                            vi: 'customer-engagement',
                        },
                    },
                    {
                        name: 'ecommerce-marketing',
                        paths: {
                            default: 'ecommerce-marketing',
                            de: 'ecommerce-marketing',
                            it: 'ecommerce-marketing',
                            pl: 'ecommerce-marketing',
                            es: 'e-commerce-marketing',
                            fr: 'marketing-pour-e-commerce',
                            pt: 'marketing-comercio-eletronico',
                            vi: 'ecommerce-marketing',
                        },
                        children: [
                            {
                                name: 'magento',
                                paths: {
                                    default: 'magento',
                                    de: 'magento',
                                    it: 'magento',
                                    pl: 'magento',
                                    es: 'magento',
                                    fr: 'magento',
                                    pt: 'magento',
                                    vi: 'magento',
                                    ru: false,
                                },
                            },
                            {
                                name: 'prestashop',
                                paths: {
                                    default: 'prestashop',
                                    de: 'prestashop',
                                    it: 'prestashop',
                                    pl: 'prestashop',
                                    es: 'prestashop',
                                    fr: 'prestashop',
                                    pt: 'prestashop',
                                    vi: 'prestashop',
                                    ru: false,
                                },
                            },
                        ],
                    },
                    {
                        name: 'lead-generation-software',
                        paths: {
                            default: 'lead-generation-software',
                            pl: 'generowanie-leadow',
                            de: 'leadgenerierung-software',
                            es: 'software-de-generacion-de-contactos',
                            fr: 'logiciel-de-lead-generation',
                            pt: 'software-de-geracao-de-leads',
                            ru: 'instrumenty-lidogeneratsii',
                            it: 'lead-generation-software',
                            zh: 'lead-generation-software',
                            tr: 'lead-generation-software',
                            vn: 'lead-generation-software',
                        },
                    },
                    {
                        name: 'selling-knowledge',
                        paths: {
                            default: 'selling-knowledge',
                            de: 'wissen-verkaufen',
                            es: 'venta-de-conocimiento',
                            fr: 'vendre-son-expertise',
                            it: 'vendere-know-how',
                            pl: 'jak-sprzedawac-wiedze',
                            pt: 'vender-conhecimento',
                            ru: 'zarabotat-na-znanijah',
                            vi: 'selling-knowledge',
                        },
                    },
                ],
            },
            {
                name: 'webinar-library',
                paths: {
                    default: 'webinar-library',
                    pl: 'biblioteka-webinarow',
                    de: 'webinar-bibliothek',
                    it: 'raccolta-webinar',
                    es: false,
                    fr: false,
                    pt: false,
                    vn: false,
                    ru: false,
                },
            },
        ],
    },
};

// export default routes;
exports.routes = routes;
