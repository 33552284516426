const getRoutesTree = require('../getRoutesTree');
const { match, compile } = require('path-to-regexp');
const { locales: localesWithHosts } = require('../config/constants');
const routes = getRoutesTree();

const locales = Object.keys(localesWithHosts).map((locale) => localesWithHosts[locale].localeParam);

/**
 * @description serializes path
 * @param {{path: string; localizedParentPath?: string}}
 * @returns { string } The serialized path: i.e. "/funktionen/marketingautomatisierung"
 */
function serializePath({ path, localizedParentPath }) {
    return localizedParentPath ? [localizedParentPath, path].join('/').replace('//', '/') : '/' + path;
}

const getRoutes = () => {
    return getFlatRoutes(routes);
};

/**
 * @returns {Route[]} An array of Route objects representing the flat routes.
 */
const getFlatRoutes = (routes, parent) => {
    let routeList = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(routes).forEach(([_key, data]) => {
        const canonicalName = data?.paths?.default ?? data.name;
        const route = {
            path: `${parent ? [parent?.path, canonicalName].join('/').replace('//', '/') : '/' + canonicalName}`,
            fallback: data.fallback,
            featureToggleName: data.featureToggleName,
            featureToggleFallback: data.featureToggleFallback,
            localization: locales.map((locale) => {
                let path = data?.paths?.[locale] ? data?.paths?.[locale] : canonicalName;
                let localizedParentPath = parent?.localization?.find((n) => n.locale === locale).path;
                return {
                    locale,
                    path: serializePath({ localizedParentPath, path }),
                    disabled: data?.paths?.[locale] === false ? true : undefined,
                };
            }),
        };

        if (!route.fallback) {
            routeList.push(route);
        }

        if (data.children) {
            routeList = [...routeList, ...getFlatRoutes(data.children, route)];
        }
    });

    return routeList;
};

const flatRoutes = getRoutes();

const dynamicRouteMatches = (routePath, path) => {
    const matchFn = match(routePath, { decode: decodeURIComponent });
    const m = matchFn(path);
    if (m !== false) {
        return m;
    }
    return false;
};

const removeLocaleFromPathname = (pathname) => {
    let pathnameWithoutLocale = pathname.replace('/[locale]', '').replace('/:locale', '');
    if (pathnameWithoutLocale === '') {
        pathnameWithoutLocale = '/';
    }
    if (pathnameWithoutLocale[0] !== '/') {
        pathnameWithoutLocale = `/${pathnameWithoutLocale}`;
    }
    return pathnameWithoutLocale;
};

/**
 * @returns {Route} An array of Route objects representing the flat routes.
 */
const getMatchingRoute = (pathname) => {
    const pathnameWithoutLocale = removeLocaleFromPathname(pathname);
    let route = flatRoutes.find((route) => {
        return route.path === pathnameWithoutLocale;
    });

    if (route) {
        return route;
    }

    let params;

    route = flatRoutes.find((route) => {
        const match = dynamicRouteMatches(route.path, pathnameWithoutLocale);
        if (match) {
            params = match.params;
            return true;
        }
    });
    return { ...route, params };
};

const getLocalizedPathname = (pathname, locale, params) => {
    if (!locales.includes(locale)) {
        throw new Error(`Invalid locale ${locale}`);
    }
    let route = getMatchingRoute(pathname);
    if (route) {
        try {
            const routeLocalization = route.localization.find((l) => l.locale === locale);
            const localizedPath = routeLocalization.path;
            if (routeLocalization.disabled) {
                return false;
            }
            const toPath = compile(localizedPath, { encode: encodeURIComponent });
            try {
                return toPath(params);
            } catch (e) {
                try {
                    return toPath({ ...route.params });
                } catch (er) {
                    return localizedPath;
                }
            }
        } catch (e) {
            throw new Error(`Unable to translate pathname ${pathname} to locale ${locale}`, e);
        }
    }
    throw new Error(`No matching route found for pathname: ${pathname}, locale: ${locale}`);
};

/**
 *
 * @param {string} pathname
 * @param {string} [locale]
 * @param {object} [params]
 * @returns {string}
 */
const getLocalizedUrl = (pathname, locale, params) => {
    const localizedPathname = getLocalizedPathname(pathname, locale, params);
    if (localizedPathname === false) {
        return false;
    }
    const host = localesWithHosts[locale.replace('vn', 'vi')].hostWithPort;
    const hasSeparateDomain = localesWithHosts[locale.replace('vn', 'vi')].hasSeparateDomain;
    const localeParam = localesWithHosts[locale.replace('vn', 'vi')].localeParam;
    return `${process.env.NEXT_PUBLIC_PROTOCOL}//${host}${hasSeparateDomain ? '' : '/' + localeParam}${
        hasSeparateDomain ? localizedPathname : localizedPathname.replace(/\/$/i, '')
    }`;
};

module.exports = {
    routes: flatRoutes,
    getLocalizedPathname,
    getMatchingRoute,
    getLocalizedUrl,
};
